import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { mobileSubmenu } from '../../../animations/mobile-submenu.animation';
import { availableLanguages } from '../../../config/translate.config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [mobileSubmenu],
})
export class HeaderComponent implements OnInit {
  public currentLang: string;
  public langSwitcherOpen = false;
  public mobileSubmenuOpen = false;
  public languages = availableLanguages;
  public langToText = {
    sl: 'slo',
    en: 'eng',
    de: 'de',
    it: 'it',
  };
  @ViewChild('langSwitcher') private langSwitcher: ElementRef;

  constructor(
    private readonly translate: TranslateService,
    protected readonly cookieService: CookieService,
    protected readonly renderer: Renderer2,
  ) {}

  public ngOnInit(): void {
    /**
     * This events get called by all clicks on the page
     */
    this.renderer.listen('window', 'click', (e: Event) => {
      if (!this.langSwitcher.nativeElement.contains(e.target)) {
        this.langSwitcherOpen = false;
      }
    });
    this.currentLang = this.translate.currentLang;
    this.translate.onLangChange.subscribe((event) => {
      this.changeLang(event.lang);
    });
  }

  public toggleLangSwitcher(): void {
    this.langSwitcherOpen = !this.langSwitcherOpen;
  }

  public changeLang(newLang: string): void {
    this.currentLang = newLang;
    this.translate.use(newLang);
    this.cookieService.set('kosir-lang', newLang);
  }

  public toggleMobileSubmenu(): void {
    this.mobileSubmenuOpen = !this.mobileSubmenuOpen;
  }
}
